window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});
 // Erstelle ein Eingabefeld für das aktuelle Datum
const inputSubscribeDate = document.createElement('input');
inputSubscribeDate.type = 'text';
inputSubscribeDate.name = 'SubscribeDate';
inputSubscribeDate.id = 'SubscribeDate';
inputSubscribeDate.setAttribute('data-label', 'Subscribe Date');
inputSubscribeDate.value = new Date().toISOString().slice(0, 10); // Aktuelles Datum im Format JJJJ-MM-TT
inputSubscribeDate.style.display = 'none'; // Ausblenden
 
// Erstelle ein Eingabefeld für die zufällige Zeichenfolge
const inputDoitoken = document.createElement('input');
inputDoitoken.type = 'text';
inputDoitoken.name = 'Doitoken';
inputDoitoken.id = 'Doitoken';
inputDoitoken.setAttribute('data-label', 'Doitoken');
inputDoitoken.value = generateRandomString(16); // Zufällige alphanumerische Zeichenfolge mit 16 Zeichen
inputDoitoken.style.display = 'none'; // Ausblenden
 
// Füge die Eingabefelder zum Formular hinzu
document.addEventListener('DOMContentLoaded', () => {
    const form = document.getElementsByTagName('form')[0];
    form.appendChild(inputSubscribeDate);
    form.appendChild(inputDoitoken);
});
 
// Funktion zur Generierung einer zufälligen alphanumerischen Zeichenfolge
function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}